import React, { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { IconContext } from '@react-icons/all-files'
import { FaClinicMedical } from '@react-icons/all-files/fa/FaClinicMedical'
import { GoCreditCard } from '@react-icons/all-files/go/GoCreditCard'

import Layout from '../components/layout'

import Card from '@/components/card'
import { routes } from '@/routes'

const IndexPage = (): ReactElement => {
  return (
    <Layout>
      <h1>Velkommen til Kiropraktor Akutten!</h1>
      <p>Hos oss får du akuttime på dagen, også i helger og på kveldstid.</p>

      <p>Behandlingen tilpasses deg som pasient.</p>

      <p>Fokuset er å gjøre deg raskt og effektivt smertefri, og minimere risiko for tilbakefall.</p>

      <p>Hver behandling varer i 20-30 minutt.</p>

      <p>Hos Kiropraktor Akutten får du en komplett behandling hos en og samme terapeut!</p>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 pt-8 mt-8 border-t border-gray-200 border-solid">
        <Card
          title={routes.omKiropraktikk.title}
          desc="Les mer om kiropraktikk"
          url={routes.omKiropraktikk.href}
          cover={
            <StaticImage
              src="../images/shutterstock_233854501_web.png"
              alt="kiropraktikk"
              layout="fullWidth"
              aspectRatio={1.2}
              className="h-full rounded-l-lg"
              imgClassName="rounded-lg"
            />
          }
        />
        <Card
          title={routes.omOss.title}
          desc="Les mer om din kiropraktor"
          url={routes.omOss.href}
          cover={
            <StaticImage
              src="../images/christer-forland.jpg"
              alt="Christer Førland"
              layout="fullWidth"
              aspectRatio={1.2}
              transformOptions={{ grayscale: true }}
              className="h-full rounded-l-lg"
              imgClassName="rounded-lg"
            />
          }
        />
        <Card
          title={routes.behandling.title}
          desc="Les mer om behandlingsmetoder og behandlingstilbud"
          url={routes.behandling.href}
          cover={
            <StaticImage
              src="../images/gymball.jpg"
              alt="Gravide kvinne"
              layout="fullWidth"
              // height={120}
              aspectRatio={1.2}
              className="h-full rounded-l-lg"
              imgClassName="rounded-lg"
            />
          }
        />
        <Card
          title={routes.klinikk.title}
          desc="Vår klinikk i Sandnes og Stavanger"
          url={routes.klinikk.href}
          cover={
            <IconContext.Provider value={{ className: 'h-16 w-16 mx-auto' }}>
              <div className="flex justify-center items-center h-full text-white bg-primary rounded-l-lg">
                <FaClinicMedical />
              </div>
            </IconContext.Provider>
          }
        />
        <Card
          title={routes.priser.title}
          desc="Les mer om behandlingsprisene"
          url={routes.priser.href}
          cover={
            <IconContext.Provider value={{ className: 'h-16 w-16 mx-auto' }}>
              <div className="flex justify-center items-center h-full text-white bg-primary rounded-l-lg">
                <GoCreditCard />
              </div>
            </IconContext.Provider>
          }
        />
      </section>
    </Layout>
  )
}

export default IndexPage
