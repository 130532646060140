import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

interface CardProps {
  cover: ReactElement
  title: string
  url: string
  desc: string
}

function Card({ title, url, cover, desc }: CardProps): ReactElement {
  return (
    <Link to={url} className="grid grid-cols-3 h-[120px] !no-underline rounded-lg shadow-md hover:shadow-xl">
      <div className="col-span-1 rounded-l-lg">{cover}</div>
      <div className="flex flex-col col-span-2 justify-center pr-2 pl-3 bg-gray-50">
        <span className="text-lg font-semibold tracking-widest">{title}</span>
        <span className="leading-tight text-gray-500 text-md">{desc}</span>
      </div>
    </Link>
  )
}

export default Card
